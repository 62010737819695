export const initAutocomplete = ({ $input, apiKey }) => {
    import("@googlemaps/js-api-loader").then(({ Loader }) => {
        const loader = new Loader({
            apiKey,
            version: "weekly",
            libraries: ["places"],
        });

        loadMap({ loader });
    });

    function loadMap({ loader }) {
        loader.load().then(async () => {
            const autocomplete = new google.maps.places.Autocomplete($input);
            const $form = $input.closest("form");

            autocomplete.setTypes(["geocode"]);

            google.maps.event.addListener(
                autocomplete,
                "place_changed",
                function () {
                    const place = autocomplete.getPlace();
                    if (!place.geometry) {
                        return;
                    }

                    let address = [];
                    if (place.address_components) {
                        address = [
                            (place.address_components[0] &&
                                place.address_components[0].short_name) ||
                                "",
                            (place.address_components[1] &&
                                place.address_components[1].short_name) ||
                                "",
                            (place.address_components[2] &&
                                place.address_components[2].short_name) ||
                                "",
                        ].join(" ");
                    }

                    // console.log({ address });
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();

                    setElement($form, "latitude", lat);
                    setElement($form, "longitude", lng);
                }
            );
        });
    }
};

function setElement($form, name, value) {
    let $element = $form.elements.namedItem(name);

    if (!$element) {
        $element = document.createElement("input");
        $element.type = "hidden";
        $element.name = name
        $form.appendChild($element);
    }
    $element.value = value;
}
