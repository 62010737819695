import { initAutocomplete } from "./map";
import { callApi } from "./utils";

(function ($) {
    $(window).on("elementor/frontend/init", function (e) {
        const IS_EDIT = elementorFrontend.config.environmentMode.edit;

        if (IS_EDIT) {
            // console.log(window.elementor.$preview);
            window.elementor.hooks.addAction(
                "panel/open_editor/widget",
                function (panel, model, view) {
                    console.log({ panel });
                }
            );
        } else {
            elementorFrontend.hooks.addAction(
                "frontend/element_ready/widget",
                function ($scope) {
                    const { widget_type, id } = $scope.data();
                    const IS_FORM = widget_type.split(".")[0].includes("form");

                    if (IS_FORM) {
                        const $form =
                            $scope[0]?.querySelector(".elementor-form");

                        if (!$form) return;

                        callApi({
                            action: "search_products",
                            data: {
                                form_id: id,
                                post_id:
                                    $form.querySelector('[name="post_id"]')
                                        .value,
                            },
                        }).then((resp) => {
                            const { data } = resp;

                            if (data?.FORM === "search-products") {
                                const $input = $form.querySelector(
                                    `[name='form_fields[${data.autocomplete}]']`
                                );
                                const { api: apiKey } = data;

                                initAutocomplete({ $input, apiKey });
                                createItems(data);
                            }
                        });
                    }
                }
            );
        }
    });

    document.addEventListener("DOMContentLoaded", init);

    function init() {
        $(document).on("submit_success", function (event, response) {
            if (response?.data?.FORM === "search-products") {
                createItems(response?.data);
            }
        });
    }

    function getTag(name = "") {
        if (name.includes("image")) return "img";

        if (name.includes("heading")) return "h1,h2,h3,h4,h5";

        if (name.includes("text-editor")) return "p";

        if (name.includes("button")) return "a";
    }

    function createItems(data) {
        let $container = document.querySelector(`#${data.container}`);

        if (!$container) return;

        const { template, products } = data;

        const rows = [];

        const elements = [
            {
                "data-product-image": "image",
            },
            {
                "data-product-title": "title",
            },
            {
                "data-product-description": "description",
            },
            {
                "data-product-price": "price",
            },
            {
                "data-product-link": "link",
            },
        ];

        Array.from(products).forEach((product) => {
            const $template = document.createElement("template");
            $template.innerHTML = template;

            elements.forEach((element) => {
                const key = Object.keys(element)[0];
                const property = Object.values(element)[0];

                const $element = $template.content.querySelector(`[${key}]`);
                if (!$element) return;

                const { widget_type } = $element.dataset;
                const [tagName, _] = widget_type.split(".");

                const tag = getTag(tagName);

                if (key === "data-product-image") {
                    const $img = $element.querySelector(tag);
                    $img.insertAdjacentHTML("afterend", product[property]);
                    $img.remove();
                    return;
                }

                if (key === "data-product-link") {
                    $element.querySelector(tag).href = product[property];
                    return;
                }

                $element.querySelector(tag).innerHTML = product[property];
            });

            rows.push(`<article>${$template.innerHTML}</article>`);
        });
        const $conInner = $container.querySelector(".e-con-inner");
        const html = rows.join("");

        if ($conInner) {
            $conInner.innerHTML = html;
            return;
        }

        $container.innerHTML = html;
    }
})(jQuery);
