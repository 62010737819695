export const callApi = ({ action, type = "POST", data }) => {
	return new Promise((resolve, reject) => {
		jQuery.ajax({
			type,
			url: '/wp-admin/admin-ajax.php',
			data: {
				action,
				...data,
			},
			dataType: "json",
			success: function (response) {
				resolve(response)
            },
		})
	})
}
